import {
  formatCryptoQuantity,
  formatCurrency,
  formatHash,
  formatUnixTime,
  Icons,
  Paper,
} from "@bakkt/bakkt-ui-components";
import { Box, IconButton, Snackbar, SvgIcon, Tooltip, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { DataGridPremium, GridColDef, GridPagination, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { useAsyncValue } from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon.tsx";
import { RootContextType, useRootContext } from "../../RootLayout";
import { WalletTransaction } from "../../services/openAPI/internal";
import { formatCorrelationId, getPriceByAssetQty, getSVGStringForTicker, mapFauxIds } from "../../utils/dataUtils.ts";

export function AllTransactionsGrid() {
  const { priceFeed, assets } = useRootContext() as RootContextType;
  const transactions: any = useAsyncValue() as WalletTransaction[];
  const [open, setOpen] = useState(false);
  const [copyItemLabel, setCopyItemLabel] = useState("");
  const handleCopyClick = (hash: string, itemLabel: string) => {
    setOpen(true);
    setCopyItemLabel(itemLabel);
    navigator.clipboard.writeText(hash);
  };

  const dataGridSx = {
    "& .MuiDataGrid-virtualScroller": {
      overflow: "scroll",
    },
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "DATE",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return <span>{formatUnixTime(params.value, "long")}</span>;
        }
      },
      width: 180,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      editable: false,
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 95,
      editable: false,
      renderCell: (params) => {
        return <>{params.value.replaceAll("_", " ")}</>;
      },
    },
    {
      field: "assetTicker",
      headerName: "ASSET",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, params.value),
                title: params.value,
                sx: { width: 25, height: 25, pr: 1 },
              })
            }
            inheritViewBox
          />
          {params.value}
        </>
      ),
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            params.value && (
              <Box>
                <IconButton aria-label="copy" onClick={() => handleCopyClick(params.value, "quantity")}>
                  <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
                </IconButton>
                <Tooltip
                  title={`${formatCryptoQuantity(params.value, params.row.assetTicker, "long")}  ${
                    params.row.assetTicker
                  }`}
                >
                  <span>{formatCryptoQuantity(params.value) + " " + params.row.assetTicker}</span>
                </Tooltip>
                <Typography variant={"body2"} sx={{ ml: 4, position: "relative", top: -6 }}>
                  {formatCurrency(getPriceByAssetQty(params.row.assetTicker, params.value, priceFeed, assets))}
                </Typography>
              </Box>
            )
          );
        }
      },
    },
    {
      field: "fee",
      headerName: "FEES",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Box>
              <Typography variant={"body2"}>
                {parseFloat(params.value.toFixed(6)) + " " + params.row.assetTicker}
              </Typography>
              <Typography variant={"body2"}>
                {formatCurrency(getPriceByAssetQty(params.row.assetTicker, params.value, priceFeed, assets))}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "amountUSD",
      headerName: "VALUE",
      sortable: false,
      width: 120,
      renderCell: (params) => <>{formatCurrency(params.value)}</>,
    },
    {
      field: "blockchainTransactionId",
      headerName: "HASH",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleCopyClick(params.value, "hash")}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{formatHash(params.value)}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "correlationId",
      headerName: "CORRELATION ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleCopyClick(params.value, "correlation id")}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{formatCorrelationId(params.value)}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "sourceName",
      headerName: "WALLET ORIGINATION",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
    },
    {
      field: "sourceAddress",
      headerName: "WALLET ORIGINATION ADDRESS",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <Tooltip title={params.value}>
                <span>{params.value}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "destinationName",
      headerName: "DESTINATION",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
    },
    {
      field: "createdBy",
      headerName: "ORIGINATOR",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 165,
    },
    {
      field: "destinationAddress",
      headerName: "DESTINATION ADDRESS",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <Tooltip title={params.value}>
                <span>{params.value}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "accountId",
      headerName: "ACCOUNT",
    },
    {
      field: "organizationId",
      headerName: "ORGANIZATION",
    },
    {
      field: "sourceId",
      headerName: "SOURCE WALLET ID",
      description: "This column is not sortable.",
      sortable: false,
      width: 80,
    },
    {
      field: "destinationId",
      headerName: "DESTINATION WALLET ID",
      description: "This column is not sortable.",
      sortable: false,
      width: 80,
    },
    {
      field: "traceWalletId",
      headerName: "TRACE WALLET ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        if (params.row.type === "Deposit") {
          return `${params.row.destinationId}`;
        } else {
          return `${params.row.sourceId}`;
        }
      },
    },
    {
      field: "settlementCorrelationId",
      headerName: "SETTLEMENT CORRELATION ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        amountUSD: false,
        accountId: false,
        organizationId: false,
        sourceAddress: false,
        destinationAddress: false,
        sourceId: false,
        destinationId: false,
        traceWalletId: false,
        settlementCorrelationId: false,
      },
    },
  };
  const data = { columns, initialState, rows: mapFauxIds(transactions) };

  return (
    <>
      <Paper>
        <Grid container>
          <DataGridPremium
            slots={{
              toolbar: GridToolbar,
              pagination: GridPagination,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                },
                csvOptions: {
                  fileName: `Operations Client Transactions ${formatUnixTime(Date.now())}`,
                  allColumns: true,
                },
                excelOptions: {
                  fileName: `Operations Client Transactions ${formatUnixTime(Date.now())}`,
                  allColumns: true,
                },
              },
            }}
            {...data}
            initialState={{
              ...data.initialState,
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                ...data.initialState.columns,
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
              ...initialState,
            }}
            sx={dataGridSx}
            pageSizeOptions={[10, 20, 50]}
            pagination
            disableRowSelectionOnClick
          />
        </Grid>
        <Snackbar
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          autoHideDuration={2000}
          message={`Copied ${copyItemLabel} to clipboard`}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
      </Paper>
    </>
  );
}

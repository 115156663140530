import { Alert, Button, Dialog, DialogTitle, Skeleton, UserInfo } from "@bakkt/bakkt-ui-components";
import { AlertTitle, Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";

import useReviewTransaction from "../../Hooks/useReviewTransaction";
import { useRootContext } from "../../RootLayout";
import { PolicyAction, PolicyActionActionEnum, WalletTransactionTypeEnum } from "../../services/openAPI/internal";
import { PolicyService } from "../../services/serviceLoader";
import { getMinimunFeeAmount } from "../../utils/dataUtils";
import { isDa, isPolicyDisabled } from "../../utils/permissionsUtil";
import ReviewDepositDetails from "./ReviewDepositDetails";
import ReviewWithdrawDetails from "./ReviewWithdrawDetails";

const ReviewTransaction = () => {
  const { userInfo, addAlert } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
    addAlert: any;
  };
  const { assets } = useRootContext();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const { state } = useLocation();

  const reviewItem = state.data;
  const isApproved = state.approved;

  const [open, setOpen] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);

  const {
    isLoading,
    reviewTransaction,
    wallet,
    allowlistAddresses,
    walletTransactionItems,
    videoAuthOrgUsers,
    videoAuthIsRequired,
    videoAuthAffirmed,
    setVideoAuthAffirmed,
    networkFeeEstimation,
    networkFeeTicker,
  } = useReviewTransaction(reviewItem);

  // handle confirm video auth checkbox
  const handleVideoAuthCheckbox = (): void => {
    setVideoAuthAffirmed((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(`/${PolicyActionActionEnum.Deny.toLowerCase()}-transaction/${reviewItem.policyActionId}`, {
      state: reviewItem,
    });
  };

  const handleConfirmWithdraw = () => {
    navigate(`/${PolicyActionActionEnum.Approve.toLowerCase()}-transaction/${reviewItem.policyActionId}`, {
      state: reviewItem,
    });
  };

  // Deposit functions and effects
  const handleQuarantine = () => {
    navigate(`/confirm-quarantine/`, {
      state: reviewItem,
    });
  };

  const handleConfirmDeposit = () => {
    const approvalData = {
      action: PolicyActionActionEnum.Approve,
      policyActionId: reviewItem.policyActionId,
      organizationId: reviewItem.organizationId,
      accountId: reviewItem.clientId,
    };
    fetcher.submit(JSON.stringify(approvalData), {
      method: "post",
      encType: "application/json",
    });
    navigate(`/`);
  };

  useEffect(() => {
    const response = fetcher.data as AxiosResponse;
    if (response?.status === 200) {
      addAlert({
        severity: "success",
        messageHeader: "Deposit Accepted",
      });
      navigate(`/`);
    }
  }, [fetcher.data]);

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };
  const minimumFeeAmount = getMinimunFeeAmount(reviewTransaction.assetSymbol, assets);

  const isDisabled =
    isPolicyDisabled(reviewItem.policyInstanceStatus, reviewItem.policySequenceStatus, userInfo, isApproved) ||
    (reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Withdraw && !videoAuthAffirmed);

  const isQuarantineDisabled =
    isDisabled || (reviewTransaction.isAssetToken && (reviewTransaction?.gasBalance || 0) < minimumFeeAmount);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle
          title={
            reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Deposit
              ? "Review Deposit"
              : `Review and Approve ${
                  reviewItem.policyInstanceRequestType[0] + reviewItem.policyInstanceRequestType.slice(1).toLowerCase()
                }`
          }
          severity="warning"
        >
          {reviewItem.policyInstanceRequestCategory === WalletTransactionTypeEnum.Deposit
            ? "Review the deposit details below."
            : "Organization has approved the below transaction."}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Skeleton variant="rectangular" height={110} />
          ) : isError ? (
            <Alert severity="error">
              <AlertTitle>Error loading withdraw review data.</AlertTitle>
              Please try again or contact support for assistance.
            </Alert>
          ) : (
            <>
              {reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Deposit ? (
                <>
                  <ReviewDepositDetails
                    deposit={reviewTransaction}
                    allowListedAddresses={allowlistAddresses}
                    wallet={wallet ? wallet : undefined}
                    walletTransactions={walletTransactionItems}
                  />
                </>
              ) : (
                <>
                  {videoAuthIsRequired && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                      <AlertTitle>This transaction requires video authorization.</AlertTitle>
                      Please confirm below that you have verified this transaction with one of the following parties:
                      <Box sx={{ pt: 2, mb: 2 }}>
                        {videoAuthOrgUsers.map((vidAuthUser) => (
                          <Typography variant={"subtitle2"} key={vidAuthUser.id}>
                            {vidAuthUser.name.firstName + " " + vidAuthUser.name.lastName + ": " + vidAuthUser.email}
                          </Typography>
                        ))}
                      </Box>
                      <FormControlLabel
                        control={<Checkbox checked={videoAuthAffirmed} onChange={handleVideoAuthCheckbox} />}
                        label="Verified transaction by video with this client"
                      />
                    </Alert>
                  )}
                  <ReviewWithdrawDetails
                    withdraw={reviewTransaction}
                    allowListedAddresses={allowlistAddresses}
                    wallet={wallet ? wallet : undefined}
                    walletTransactions={walletTransactionItems}
                    feeEstimation={networkFeeEstimation}
                    networkFeeTicker={networkFeeTicker ?? undefined}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleClose} sx={thirdActionSx}>
            Go Back
          </Button>

          {reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Deposit
            ? isDa(userInfo) && (
                <>
                  <Button
                    variant={"outlined"}
                    onClick={handleQuarantine}
                    sx={{ mr: 1 }}
                    disabled={isQuarantineDisabled}
                  >
                    Quarantine
                  </Button>
                  <Button variant={"contained"} onClick={handleConfirmDeposit} autoFocus disabled={isDisabled}>
                    Accept
                  </Button>
                </>
              )
            : isDa(userInfo) && (
                <>
                  <Button variant={"outlined"} onClick={handleDecline} sx={{ mr: 1 }} disabled={isDisabled}>
                    Decline
                  </Button>
                  <Button variant={"contained"} onClick={handleConfirmWithdraw} autoFocus disabled={isDisabled}>
                    Approve
                  </Button>
                </>
              )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewTransaction;

export async function action({ request }: { request: Request }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyServiceAction = await PolicyService.applyAction(reviewPolicyRequest);
    return policyServiceAction;
  } catch (e) {
    return e;
  }
}

import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  formatWalletAddress,
  Icons,
  Skeleton,
  Typography,
} from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  Box,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import useReviewWalletActivate from "../../Hooks/useReviewWalletActivate";
import { useRootContext } from "../../RootLayout";
import { PolicyAction, PolicyActionActionEnum } from "../../services/openAPI/internal";
import { PolicyService } from "../../services/serviceLoader";
import { getSVGStringForTicker } from "../../utils/dataUtils";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils";

const ReviewWalletActivate = () => {
  const { assets, addAlert, setShouldRefreshPolicyItems } = useRootContext();
  const [open, setOpen] = useState(true);
  const [approvedWallet, setApprovedWallet] = useState(false);
  const [showWalletAddressConfirm, setShowWalletAddressConfirm] = useState(false);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { state } = useLocation();
  const reviewItem = state.data;
  const isApproved = state.approved;

  const { isLoading, wallet, feeWallet } = useReviewWalletActivate(reviewItem);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleApproveWalletActivate = () => {
    const approvalData = {
      action: PolicyActionActionEnum.Approve,
      policyActionId: reviewItem.policyActionId,
      organizationId: reviewItem.organizationId,
      accountId: reviewItem.clientId,
    };
    fetcher.submit(JSON.stringify(approvalData), {
      method: "post",
      encType: "application/json",
    });
  };

  const copyWalletAddress = (address: string) => {
    setShowWalletAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const copyIconStyle = { width: 15, height: 15, mr: 1 };

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const walletAsset = assets.find((asset) => asset.symbol === feeWallet?.assetSymbol);

  useEffect(() => {
    const response = fetcher.data;

    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Client will be notified of wallet creation.",
        });
        navigate("/");
      }
    }
  }, [fetcher.data]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle title={"Retry Activate New Wallet"} severity="warning">
          Organization has created the below wallet.
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Skeleton variant="rectangular" height={400} />
          ) : (
            <>
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>Retry Wallet Creation in Fireblocks Dashboard</AlertTitle>
                <Box sx={{ pt: 2, mb: 2 }}>
                  {walletAsset?.network} Tokens require a wallet activation fee transaction that must be approved by a Fireblocks Signer
                  User Account. Please confirm that a Signer User has logged into the Fireblocks Dashboard and
                  successfully retried this {walletAsset?.network} Token Wallet Creation.
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={approvedWallet}
                      onChange={() => setApprovedWallet((approvedWallet) => !approvedWallet)}
                      disableRipple
                    />
                  }
                  label="Activated New Wallet"
                />
              </Alert>

              <Grid xs={12}>
                <Typography sx={{ fontWeight: 600 }}>Source</Typography>
                <Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
                    <Grid>
                      <Typography variant="subtitle2">CLIENT</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">{reviewItem.client}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
                    <Grid>
                      <Typography variant="subtitle2">ORGANIZATION</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">{reviewItem.organization}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
                    <Grid>
                      <Typography variant="subtitle2">REQUESTED BY</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">{reviewItem.requester}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 2 }}>
                    <Grid>
                      <Typography variant="subtitle2">NETWORK</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: getSVGStringForTicker(assets, feeWallet?.assetTicker as string),
                              title: feeWallet?.assetTicker as string,
                              sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                            })
                          }
                          inheritViewBox
                        />
                        {wallet?.network}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 3 }}>
                    <Grid>
                      <Typography variant="subtitle2">WALLET</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle1">
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: getSVGStringForTicker(assets, wallet?.assetTicker as string),
                              title: wallet?.assetTicker as string,
                              sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                            })
                          }
                          inheritViewBox
                        />
                        {wallet?.name}
                      </Typography>
                      <Typography variant="subtitle1">
                        <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
                          <Tooltip
                            onClose={() => setShowWalletAddressConfirm(false)}
                            open={showWalletAddressConfirm}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            title="Address Copied"
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={() => copyWalletAddress(wallet?.address as string)}
                              disableRipple
                            >
                              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                        {formatWalletAddress((wallet?.address || "") as string)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"} sx={{ mb: 3 }}>
                    <Grid>
                      <Typography variant="subtitle2">FEE WALLET</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle1">
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: getSVGStringForTicker(assets, feeWallet?.assetTicker as string),
                              title: feeWallet?.assetTicker as string,
                              sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                            })
                          }
                          inheritViewBox
                        />
                        {feeWallet?.name}
                      </Typography>
                      <Typography variant="subtitle1">
                        <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
                          <Tooltip
                            onClose={() => setShowWalletAddressConfirm(false)}
                            open={showWalletAddressConfirm}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            title="Address Copied"
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={() => copyWalletAddress(feeWallet?.address as string)}
                              disableRipple
                            >
                              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                        {formatWalletAddress((feeWallet?.address || "") as string)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleClose} sx={thirdActionSx}>
            Go Back
          </Button>

          <Button
            variant={"contained"}
            autoFocus
            onClick={handleApproveWalletActivate}
            disabled={!approvedWallet && isApproved}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewWalletActivate;

export async function action({ request }: { request: Request }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyServiceAction = await PolicyService.applyAction(reviewPolicyRequest);
    return formatActionSuccessResponse(policyServiceAction);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}

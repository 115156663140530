import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchMockDataPromiseWithDelay, wallets } from "../services/mockData";
import { PolicyActionDetails, Wallet } from "../services/openAPI/internal";
import { WalletService } from "../services/serviceLoader";
import { shouldUseMockData } from "../utils/dataUtils";

const useReviewWalletActivate = (reviewItem: PolicyActionDetails) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
  const [feeWallet, setFeeWallet] = useState<Wallet | undefined>(undefined);

  const routeParams = useParams();
  const walletId = Number(routeParams.walletId);

  const orgId = Number(JSON.parse(String(reviewItem.policyInstancePayload)).organizationId);

  const getReviewWalletActivateDetails = async () => {
    const orgWallets: Wallet[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(wallets, 1200)) as any)
      : ((await WalletService.getWallets(undefined, orgId)) as any);

    const unactivatedWallet = orgWallets.find((w) => w.walletId == walletId);
    const unactivatedWalletFeeWallet = orgWallets.find((w) => w.walletId == unactivatedWallet?.feeWalletId);

    setWallet(unactivatedWallet);
    setFeeWallet(unactivatedWalletFeeWallet);
    setIsLoading(false);
  };

  useEffect(() => {
    getReviewWalletActivateDetails();
  }, []);

  return {
    isLoading,
    wallet,
    feeWallet,
  };
};

export default useReviewWalletActivate;

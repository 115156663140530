import { color, DataGridPremium, formatUnixTime, Icons, Pagination, Paper, Tab } from "@bakkt/bakkt-ui-components";
import {
  Box,
  IconButton,
  Snackbar,
  SvgIcon,
  TablePaginationProps,
  Tabs,
  Tooltip,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import {
  GridColDef,
  gridPageCountSelector,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";

import { Account, HistorySummary, Organization, User } from "../../services/openAPI/internal";
import { formatCorrelationId } from "../../utils/dataUtils";

enum AuditLogsTabEnum {
  Organization = "Organization",
  Account = "Account",
  Internal = "Internal",
}

interface AuditLogsGridProps {
  accountHistories: HistorySummary[];
  orgHistories: HistorySummary[];
  internalHistories: HistorySummary[];
  accounts: Account[];
  organizations: Organization[];
  users: User[];
}

interface HistoryItemWithId extends HistorySummary {
  id: number;
}

export const AuditLogsGrid = memo(function ({
  accountHistories,
  orgHistories,
  internalHistories,
  accounts,
  organizations,
  users,
}: AuditLogsGridProps) {
  const tabs = [AuditLogsTabEnum.Account, AuditLogsTabEnum.Organization, AuditLogsTabEnum.Internal];
  const [selectedTab, setSelectedTab] = useState(AuditLogsTabEnum.Organization);
  const [copyId, setCopyId] = useState(false);
  const { accountId, organizationId } = useParams();

  const mapFauxIds = (list: HistorySummary[]): HistoryItemWithId[] => {
    return list.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    });
  };

  let filteredHistories: HistoryItemWithId[];
  if (selectedTab === AuditLogsTabEnum.Organization) {
    filteredHistories = mapFauxIds(orgHistories);
  } else if (selectedTab === AuditLogsTabEnum.Account) {
    filteredHistories = mapFauxIds(accountHistories);
  } else {
    filteredHistories = mapFauxIds(internalHistories);
  }

  const handleTabChange = (_: React.SyntheticEvent, value: AuditLogsTabEnum) => {
    setSelectedTab(value);
  };

  function getReportName(
    selectedTab: AuditLogsTabEnum.Internal | AuditLogsTabEnum.Organization | AuditLogsTabEnum.Account
  ): string {
    if (selectedTab === AuditLogsTabEnum.Account) {
      const accountName = accounts.find((account) => String(account.id) === accountId)?.name || "";
      return "Account " + accountName;
    } else if (selectedTab === AuditLogsTabEnum.Organization) {
      const orgName = organizations.find((org) => String(org.id) === organizationId)?.name || "";
      return "Organization " + orgName;
    } else {
      return "Internal ";
    }
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
            {tabs.map((tab) => (
              <Tab key={tab} value={tab} label={tab}></Tab>
            ))}
          </Tabs>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid container xs={12}>
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${getReportName(selectedTab)} Log ${formatUnixTime(Date.now())}`,
              allColumns: true,
            }}
            printOptions={{
              allColumns: true,
            }}
            excelOptions={{
              allColumns: true,
            }}
          />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  const dataGridSx = {
    ".MuiDataGrid-cell": {
      whiteSpace: "normal !important",
      wordWrap: "break-word !important",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: color.provocativePlum[50],
    },
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  function customDetailPanelExpandIcon() {
    return <SvgIcon component={Icons.ChevronDownIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  function customDetailPanelCollapseIcon() {
    return <SvgIcon component={Icons.ChevronUpIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  const handleCopyID = (ID: string) => {
    setCopyId(true);
    navigator.clipboard.writeText(ID);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "createdAt",
      headerName: "TIME",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return <span>{formatUnixTime(params.value, "long")}</span>;
        }
      },
      flex: 1,
    },

    {
      field: "type",
      headerName: "TYPE",
      flex: 0.6,
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      renderCell(params) {
        return params.value?.replaceAll("_", " ");
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      sortable: false,
      flex: 2,
      renderCell(params) {
        return params.value?.replaceAll("_", " ");
      },
    },
    {
      field: "client",
      headerName: "CLIENT",
      flex: 1,
      valueGetter: (params) => {
        const accountName = accounts.find((acct: Account) => acct.id === Number(params.row.accountId))?.name;
        return accountName ? accountName : "N/A";
      },
    },
    {
      field: "accountId",
      headerName: "ACCOUNT ID",
      flex: 0.6,
    },
    {
      field: "organizationId",
      headerName: "ORG ID",
      sortable: false,
      resizable: false,
      flex: 0.6,
    },
    {
      field: "organization",
      headerName: "ORGANIZATION",
      sortable: false,
      flex: 0.8,
      valueGetter: (params) => {
        const orgName = organizations.find((o: Organization) => o.id === Number(params.row.organizationId))?.name;
        return orgName ? orgName : "N/A";
      },
    },
    {
      field: "itemId",
      headerName: "ENTITY ID",
      sortable: false,
      flex: 1.2,
      resizable: false,
    },
    {
      field: "userName",
      headerName: "USER",
      flex: 1,
      resizable: false,
      sortable: false,
      valueGetter: (params) => {
        if (params.value && params.value === "Bakkt Support") {
          const filteredUser = users.find((user: User) => user.id === params.row.userId);
          return (
            params.value + " | " + (filteredUser ? filteredUser.name.firstName + " " + filteredUser.name.lastName : "")
          );
        } else {
          return params.value;
        }
      },
    },
    {
      field: "actionId",
      headerName: "CORRELATION ID",
      sortable: false,
      flex: 0.8,
      resizable: false,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else if (!params.value) {
          return "";
        } else {
          return (
            <Box>
              <IconButton aria-label="copy" onClick={() => handleCopyID(params.value)}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{formatCorrelationId(params.value)}</span>
              </Tooltip>
            </Box>
          );
        }
      },
    },
    {
      field: "settlementCorrelationId",
      headerName: "SETTLEMENT CORRELATION ID",
      sortable: false,
      flex: 1,
      resizable: false,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        organization: false,
        itemId: false,
        userName: false,
        actionId: false,
        settlementCorrelationId: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredHistories };

  const detailAuditRowItem = (id: number) => {
    return filteredHistories.filter((item: HistoryItemWithId) => item.id === id);
  };

  return (
    <>
      <Paper>
        <Grid container>
          <DataGridPremium
            density="comfortable"
            rowHeight={60}
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
              detailPanelExpandIcon: customDetailPanelExpandIcon,
              detailPanelCollapseIcon: customDetailPanelCollapseIcon,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                },
              },
            }}
            {...data}
            initialState={{
              ...data.initialState,
              sorting: {
                ...data.initialState,
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
              pagination: {
                paginationModel: { pageSize: 50, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            pagination
            disableRowSelectionOnClick
            sx={dataGridSx}
            getDetailPanelContent={({ row: auditRow }) => (
              <Paper sx={{ pl: 6.5 }}>
                <DataGridPremium
                  columns={columns.slice(8, 13)}
                  rows={detailAuditRowItem(auditRow.id)}
                  getRowId={(auditRow) => auditRow.id}
                  hideFooter
                  disableRowSelectionOnClick
                />
              </Paper>
            )}
            getDetailPanelHeight={() => "auto"}
          />
        </Grid>
        <Snackbar
          open={copyId}
          onClose={() => {
            setCopyId(false);
          }}
          autoHideDuration={2000}
          message={`Copied ID to clipboard`}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
      </Paper>
    </>
  );
});

import { Alert, Button, Dialog, DialogTitle, Skeleton, Typography } from "@bakkt/bakkt-ui-components";
import { AlertTitle, Box, DialogActions, DialogContent, Unstable_Grid2 as Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import useGetSettlementDetails, { SettlementFormData } from "../../Hooks/useGetSettlementDetails";
import { WalletTransaction } from "../../services/openAPI/internal";
import { formatCurrentDate } from "../../utils/dataUtils";
import ReviewSettlementLineItem, { SettlementLineItem } from "./ReviewSettlementLineItem";

const ReviewSettlement = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const settlementItem = state.data;
  const { settlementTransaction, isLoading } = useGetSettlementDetails(settlementItem);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  function buildSettlementLineItem(
    settlementFormData: Partial<SettlementFormData>,
    transaction: WalletTransaction
  ): SettlementLineItem {
    return {
      organizationId: settlementFormData.organizationId || 0,
      clientName: settlementFormData.clientName || "",
      orgName: settlementFormData.orgName || "",
      requesterName: settlementFormData.requesterName || "",
      createdOn: settlementFormData.createdOn || 0,
      settlementTransaction: transaction,
    };
  }

  const shouldMapOverSettlements =
    settlementTransaction && (settlementTransaction?.settlementTransactions?.length as number) > 0;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
        <DialogTitle title={`Acknowledge Settlement`} severity="warning">
          Acknowledge organization's settlement details below.
        </DialogTitle>
        {isLoading && <Skeleton variant="rectangular" height={110} />}
        {!isLoading && (
          <>
            <Alert severity="error" sx={{ m: 4 }}>
              <AlertTitle>Settlement Transaction Confirmation</AlertTitle>
              <Box sx={{ pt: 2, mb: 2 }}>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Settlement will occur within a 2 hour window of <strong>8:30am UTC on {formatCurrentDate()}</strong>.
                  Settlement operations approvals also require device signing and must be signed with urgency.
                </Typography>
              </Box>
            </Alert>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Typography sx={{ fontWeight: 600, mb: 1 }}>Settlement Transactions</Typography>
                  <Grid>
                    {shouldMapOverSettlements &&
                      settlementTransaction?.settlementTransactions?.map(
                        (transaction: WalletTransaction, index: number) => (
                          <ReviewSettlementLineItem
                            key={index}
                            settlementLineItem={buildSettlementLineItem(settlementTransaction, transaction)}
                          />
                        )
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={"flex-end"}>
                <Button variant={"contained"} onClick={handleClose} sx={{ mr: 2 }}>
                  Close
                </Button>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ReviewSettlement;

import { Paper } from "@bakkt/bakkt-ui-components";
import { Box, Grid, SvgIcon, Tab, Tabs } from "@mui/material";
import { DataGridPremium, GridColDef, GridToolbarContainer } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { Link as RouterLink, useRouteLoaderData } from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { Asset, Network } from "../../services/openAPI/internal";
import { getSVGStringForTicker } from "../../utils/dataUtils";
import { innerGridContainer } from "./styles";

enum NetworkAssetsTabEnum {
  Assets = "Assets",
  Networks = "Networks",
}

const tabs = [NetworkAssetsTabEnum.Assets, NetworkAssetsTabEnum.Networks];

function NetworkAssetsGrid() {
  const { assets, networks } = useRouteLoaderData("root") as {
    assets: Asset[];
    networks: Network[];
  };
  const [selectedTab, setSelectedTab] = useState(NetworkAssetsTabEnum.Assets);

  const handleTabChange = (_: React.SyntheticEvent, value: string) => {
    setSelectedTab(value as NetworkAssetsTabEnum);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ mb: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
          {tabs.map((tab) => (
            <Tab key={tab} value={tab} label={tab}></Tab>
          ))}
        </Tabs>
      </GridToolbarContainer>
    );
  };

  const networkColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "name",
      headerName: "NETWORK",
      width: 300,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
    },
    {
      field: "blockConfirmationCount",
      headerName: "BLOCK CONFIRMATION COUNT",
      flex: 1,
    },
    {
      field: "minimumFeeAmount",
      headerName: "MINIMUM FEE AMOUNT",
      flex: 1,
      renderCell: (params) => {
        const ticker = assets.find((a) => a.network === params.row.name && a.type === "BASE")?.ticker || "";
        return (
          <>
            {params.value} {ticker}{" "}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      headerAlign: "right",
      width: 100,
      renderCell: () => {
        return (
          <RouterLink to={"/"}>
            <Box component="span" sx={{ textDecoration: "underline" }}>
              Edit Network
            </Box>
          </RouterLink>
        );
      },
    },
  ];

  const assetColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "name",
      headerName: "ASSET",
      width: 200,
    },
    {
      field: "ticker",
      headerName: "TICKER",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <SvgIcon
              component={() =>
                AssetSVGIcon({
                  svgString: getSVGStringForTicker(assets, params.value),
                  title: params.value,
                  sx: { width: 25, height: 25, pr: 1 },
                })
              }
              inheritViewBox
            />
            {params.value}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "STATUS",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      sortable: false,
      flex: 1.3,
    },
    {
      field: "network",
      headerName: "NETWORK",
      flex: 1,
    },
    {
      field: "minimumFeeAmount",
      headerName: "MINIMUM FEE AMOUNT",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.value} {params.row.feeAsset ? params.row.feeAsset : params.row.ticker}
          </>
        );
      },
    },
    {
      field: "auc",
      headerName: "TOTAL AUC",
      flex: 0.5,
      renderCell: () => {
        return 0;
      },
    },
  ];

  const data = {
    columns: selectedTab === NetworkAssetsTabEnum.Assets ? assetColumns : networkColumns,
    rows: selectedTab === NetworkAssetsTabEnum.Assets ? assets : networks,
  };

  return (
    <Paper>
      <Grid container>
        <DataGridPremium
          sx={[innerGridContainer, { minHeight: 300 }]}
          {...data}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
            footer: () => null,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Grid>
    </Paper>
  );
}

export default NetworkAssetsGrid;

import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { DialogActions } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import { useRootContext } from "../../RootLayout";
import { PolicyAction, PolicyActionActionEnum } from "../../services/openAPI/internal";
import { PolicyService } from "../../services/serviceLoader";

const QuarantineDeposit = () => {
  const { addAlert } = useRootContext();
  const [open, setOpen] = useState(true);
  const { state } = useLocation();
  const navigate = useNavigate();
  const fetcher = useFetcher();

  useEffect(() => {
    const response = fetcher.data as AxiosResponse;
    if (response?.status === 200) {
      addAlert({
        severity: "warning",
        messageHeader: "Deposit Rejected.",
        message: `${response.status}: ${response.data.message}`,
      });
      navigate(`/`);
    }
  }, [fetcher.data]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleQuarantine = () => {
    const declineData = {
      action: PolicyActionActionEnum.Deny,
      policyActionId: state.policyActionId,
      organizationId: state.organizationId,
      accountId: state.clientId,
    };
    fetcher.submit(JSON.stringify(declineData), {
      method: "post",
      encType: "application/json",
    });
    navigate(`/`);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
      <DialogTitle title="Are you sure you want to quarantine this deposit?" severity="error">
        This will result in a system generated withdrawal from the {state.client} {state.organization} wallet to the
        Bakkt Quarantine wallet for the original Deposit Amount.
      </DialogTitle>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={"contained"} onClick={handleQuarantine} autoFocus>
          Yes, Quarantine the Deposit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuarantineDeposit;

export async function action({ request }: { request: Request }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyServiceAction = await PolicyService.applyAction(reviewPolicyRequest);
    return policyServiceAction;
  } catch (e) {
    return e;
  }
}

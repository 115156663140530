import { DataGridPremium, formatUnixTime, Icons, Pagination } from "@bakkt/bakkt-ui-components";
import { PanoramaSharp } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Snackbar,
  SvgIcon,
  TablePaginationProps,
  Tooltip,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  GridColDef,
  gridPageCountSelector,
  GridPagination,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useAsyncValue, useLoaderData, useRouteLoaderData } from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { Organization, PolicyActionDetails } from "../../services/openAPI/internal";
import { getSVGStringForTicker } from "../../utils/dataUtils";

export const AllowlistGrid = () => {
  const allowlistItems: any = useAsyncValue() as PolicyActionDetails[];
  const { userInfo, assets }: any = useRouteLoaderData("root");
  const { orgsList }: any = useLoaderData();
  const [open, setOpen] = useState(false);
  const handleClick = (hash: string) => {
    setOpen(true);
    navigator.clipboard.writeText(hash);
  };

  const csvOptions = {
    fileName: `Allowlist ${formatUnixTime(Date.now())}`,
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between">
          <GridToolbar csvOptions={csvOptions} />
          <GridToolbarQuickFilter />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  const columns: GridColDef[] = [
    {
      field: "organizationId",
      headerName: "ORGANIZATION",
      maxWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        const orgName = orgsList.find((o: Organization) => o.id === params.value)?.name;
        return params.value + (orgName ? ": " + orgName : "");
      },
    },
    {
      field: "name",
      headerName: "NAME",
      maxWidth: 300,
      flex: 1,
    },
    {
      field: "address",
      headerName: "ADDRESS",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleClick(params.value)}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <>{params.value}</>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "assetTicker",
      headerName: "ASSET",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, params.value),
                title: params.value,
                sx: { width: 25, height: 25, pr: 1 },
              })
            }
            inheritViewBox
          />
          {params.value}
        </>
      ),
    },
    {
      field: "type",
      headerName: "KEY MANAGER",
      maxWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "STATUS",
      maxWidth: 200,
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      maxWidth: 100,
      flex: 1,
    },
  ];

  const initialState = {};

  const data = { columns, initialState, rows: allowlistItems };

  return (
    <>
      <Paper>
        <Box sx={{ height: "100%", flex: 1, width: "100%" }}>
          <DataGridPremium
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                },
              },
            }}
            {...data}
            initialState={{
              ...data.initialState,
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            getRowId={(row) => row.id}
            pageSizeOptions={[10, 20, 50]}
            pagination
            disableRowSelectionOnClick
          />
        </Box>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Address Copied"
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
      </Paper>
    </>
  );
};

import { Divider, Icons, UserInfo } from "@bakkt/bakkt-ui-components";
import {
  AppBar,
  Button,
  Container,
  Menu,
  MenuItem,
  Stack,
  StyledEngineProvider,
  SvgIcon,
  Toolbar,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { NavLink, useLoaderData } from "react-router-dom";

import BakktLogo from "../../assets/images/bakkt_logo.svg";
import { handleLogout } from "../../utils/sessionUtils.ts";
import { BakktToolbar } from "./BakktToolbar";
import styles from "./Header.module.css";

export const Header = () => {
  const { userInfo } = useLoaderData() as {
    userInfo: UserInfo;
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledEngineProvider injectFirst>
      <AppBar color="primary" position="static" elevation={0} sx={{ py: 3, px: 4 }}>
        <Container disableGutters maxWidth="xl">
          <Toolbar>
            <Grid container xs={12} justifyContent="space-between" alignItems="center">
              <img src={BakktLogo} alt="Bakkt logo" className={styles["header_logo"]} />
              <Grid xs={8}>
                <Stack direction="row" spacing={4}>
                  <NavLink to="/" className="default-link-hover">
                    DASHBOARD
                  </NavLink>
                  {/* <NavLink to="/" className="default-link-hover">
                    TEAM
                  </NavLink> */}
                  {/* <NavLink to="/" className="default-link-hover">
                    REQUESTS
                  </NavLink> */}
                  <NavLink to="/clients" className="default-link-hover">
                    CLIENTS
                  </NavLink>
                  <NavLink to="/transactions" className="default-link-hover">
                    TRANSACTIONS
                  </NavLink>
                  <NavLink to="/allowlisted" className="default-link-hover">
                    ALLOWLISTED
                  </NavLink>
                  <NavLink to="/audit" className="default-link-hover">
                    AUDIT LOG
                  </NavLink>
                </Stack>
              </Grid>
              <Grid>
                <Stack direction="row" spacing={2} alignItems="center">
                  {/* <SvgIcon component={Icons.Notification} sx={{ width: 20, height: 20 }} inheritViewBox /> */}
                  <Button
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ textTransform: "none", fontWeight: 700 }}
                    color="inherit"
                    className="default-link-hover"
                  >
                    {userInfo.email} <SvgIcon component={Icons.ChevronDownIcon} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles["MuiLink-Nav-Drop"]}
                  >
                    <MenuItem onClick={handleLogout}>
                      <Typography variant="body2">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
          <Container maxWidth="xl">
            <Divider />
          </Container>
        </Container>
        <BakktToolbar />
      </AppBar>
    </StyledEngineProvider>
  );
};

import { Alert, Button, Dialog, DialogTitle, Icons, TextField, Typography } from "@bakkt/bakkt-ui-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AlertTitle,
  Box,
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React, { useState } from "react";
import {
  defer,
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";
import GoBackModal from "../../components/modal/GoBackModal.tsx";
import { useRootContext } from "../../RootLayout";
import { fetchMockDataPromiseWithDelay, organizations, userInfo } from "../../services/mockData.ts";
import { Organization, Status } from "../../services/openAPI/internal";
import { AccountService, OrganizationService, UserService } from "../../services/serviceLoader.ts";
import {
  getOrgs,
  mapOrganizationDataToForm,
  mapOrgFormDataToOrganization,
  mapUserDataToForm,
  mapUserFormDataToUserRequest,
  shouldUseMockData,
} from "../../utils/dataUtils.ts";
import { isDa } from "../../utils/permissionsUtil.ts";
import { OrgUserValidation, validateAllOrgs } from "../../utils/users/OrgUsersValidationUtils";
import { LoaderData } from "../clients/AddEditClient";
import UserOrgPermissionsSelect, { OrgPermissionsFormData } from "./UserOrgPermissionsSelect.tsx";

export interface UserFormData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  inputPhoneNumber: string;
  phoneCountryCode: string;
  nationalPhoneNumber: string;
  position: string;
  organizations: UserOrgPermission[];
  isTeamManager: boolean;
  accountId: number;
}

export interface UserOrgPermission {
  organizationId: number;
  permissions: string[];
}

export default function AddEditUsers() {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const fetcherData = fetcher.data;
  const isLoading = fetcher.state === "submitting";

  //For update client/account flow
  const { accountChanges, setAccountChanges } = useRootContext();
  const pathNames = useLocation()
    .pathname.split("/")
    .filter((path) => path !== "");
  const isUpdateMode = pathNames[pathNames.length - 1] === "edit" ? true : false;
  const { accountId } = useParams();
  let { users: usersToUpdate } = (useRouteLoaderData("clientDetail") as LoaderData) ?? {};
  usersToUpdate = usersToUpdate?.filter((user) => user.status !== Status.Deleted);

  let { organizations } = useLoaderData() as {
    organizations: Organization[];
  };
  if (isUpdateMode && accountChanges.isOrgEdited) {
    organizations = mapOrgFormDataToOrganization(accountChanges.organizations);
  }
  const blankUserFormData: UserFormData = {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
    inputPhoneNumber: "",
    phoneCountryCode: "",
    nationalPhoneNumber: "",
    position: "",
    organizations: [],
    isTeamManager: false,
    accountId: -1,
  };

  const userFormData = mapUserDataToForm(usersToUpdate, isUpdateMode);
  const [goBack, setGoBack] = useState<boolean>(false);
  const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] = useState(true);
  const [users, setUsers] = useState<UserFormData[]>(
    accountChanges?.users.length > 0 ? accountChanges.users : userFormData
  );
  const [openAccordions, setOpenAccordions] = useState<number[]>([0]);
  const [validationError, setValidationError] = useState<OrgUserValidation>();
  const handleAccordionClick = (clickedIndex: number) => {
    if (openAccordions.includes(clickedIndex)) {
      const openCopy = openAccordions.filter((element) => {
        return element !== clickedIndex;
      });
      setOpenAccordions(openCopy);
    } else {
      const openCopy = [...openAccordions];
      openCopy.push(clickedIndex);
      setOpenAccordions(openCopy);
    }
  };

  const handleCancel = () => {
    setIsAddUsersDialogOpen(false);
    if (isUpdateMode) {
      //clear RootContext
      setAccountChanges((prevState) => ({
        ...prevState,
        account: null,
        organizations: [],
        users: [],
        isOrgEdited: false,
      }));
      //to parent route
      navigate("..");
    } else {
      navigate("/");
    }
  };

  const handleNewUser = () => {
    setUsers([...users, { ...blankUserFormData }]);
    setOpenAccordions([...openAccordions, users.length]);
  };

  const handleDeleteUser = (index: number) => {
    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers.splice(index, 1);
      return updatedUsers;
    });
  };

  const handleUpdateUser = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    updateField(index, name, value);
  };

  const handleAccountPermissionsUpdate = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    if (checked) {
      updateField(index, name, true);
    } else {
      updateField(index, name, false);
    }
  };

  const updateField = (index: number, fieldName: string, value: string | boolean) => {
    const userToUpdate = users[index];
    const updatedUser = { ...userToUpdate, [fieldName]: value };

    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers[index] = updatedUser;
      return updatedUsers;
    });
  };

  const handlePhoneChange = (index: number) => (value: string, info: MuiTelInputInfo) => {
    const userToUpdate = users[index];
    const updatedUser = {
      ...userToUpdate,
      inputPhoneNumber: value,
      phoneCountryCode: info.countryCallingCode ? "+" + info.countryCallingCode : "",
      nationalPhoneNumber: info.nationalNumber || "",
    };

    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers[index] = updatedUser;
      return updatedUsers;
    });
  };

  const handlePermissionsUpdate = (userIndex: number) => (orgPermissions: OrgPermissionsFormData[]) => {
    const userToUpdate = users[userIndex];
    const updatedUser = {
      ...userToUpdate,
      organizations: orgPermissions,
    };

    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers[userIndex] = updatedUser;
      return updatedUsers;
    });
  };
  //update RootContext
  const handleAccountChange = () => {
    setAccountChanges((prevAccountChanges) => ({ ...prevAccountChanges, users }));
  };
  const handleSubmit = () => {
    const valError = validateAllOrgs(organizations, users);
    if (valError.title && valError.message) {
      setValidationError(valError);
    } else {
      if (isUpdateMode) {
        handleAccountChange();
        navigate("/clients/" + accountId + "/confirm/edit");
      } else {
        fetcher.submit(JSON.stringify(users), {
          method: "post",
          encType: "application/json",
        });
      }
    }
  };

  const handleGoBack = () => {
    setGoBack(true);
  };

  const handleConfirmGoBack = () => {
    if (isUpdateMode) {
      navigate(`/clients/${accountId}/organizations/edit`);
    } else {
      setIsAddUsersDialogOpen(false);
      navigate(-1);
    }
  };

  return (
    <>
      {goBack ? (
        <GoBackModal onConfirmGoBack={handleConfirmGoBack} goBack={goBack} setGoBack={setGoBack} />
      ) : (
        <Dialog open={isAddUsersDialogOpen} onClose={handleGoBack} maxWidth={"lg"}>
          <DialogTitle title="Add Users and Organization Permissions">
            Use the table below to add users for assigning organization access and permissions. Each organization
            requires at least one permission for each of Manage Team, Initiate, and Approve in order to continue.
          </DialogTitle>
          <DialogContent>
            {validationError && (
              <Alert severity="error">
                <AlertTitle>{validationError.title}</AlertTitle>
                {validationError.message}
              </Alert>
            )}
            {isLoading ? (
              <Grid sx={{ minHeight: 250 }}>
                <LoadingIndicator description={"Users being created. Please wait..."} />
              </Grid>
            ) : (
              <>
                {fetcherData && (
                  <Alert severity="error">
                    <AlertTitle>Permission Assignment Error Has Occurred</AlertTitle>
                    {`${fetcherData.message}: ${fetcherData.data?.message}` ||
                      "Failed to create users. Please try again later or contact support."}
                  </Alert>
                )}
                <Grid container spacing={3} sx={{ mb: 1, pt: 5 }}>
                  {users.map((user, index) => (
                    <Box key={"userRow-" + index}>
                      <Grid container spacing={2} sx={{ mt: -8, mb: -5 }} alignItems={"center"}>
                        <Grid xs={0.5}>
                          <IconButton
                            onClick={() => handleAccordionClick(index)}
                            aria-label="expand"
                            size="small"
                            sx={{ mt: 2 }}
                          >
                            {openAccordions.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </Grid>
                        <Grid xs={2}>
                          <TextField
                            value={user.firstName}
                            name={"firstName"}
                            label="First Name"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdateUser(event, index)}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            required
                          />
                        </Grid>

                        <Grid xs={2}>
                          <TextField
                            value={user.lastName}
                            name={"lastName"}
                            label="Last Name"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdateUser(event, index)}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            required
                          />
                        </Grid>

                        <Grid xs={2}>
                          <TextField
                            value={user.email}
                            name={"email"}
                            label="Email"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdateUser(event, index)}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            required
                          />
                        </Grid>

                        <Grid xs={2}>
                          <MuiTelInput
                            value={user.inputPhoneNumber}
                            name={"inputPhoneNumber"}
                            label="Phone"
                            onChange={handlePhoneChange(index)}
                            variant="standard"
                            inputProps={{ maxLength: 17 }}
                            fullWidth
                            required
                          />
                        </Grid>

                        <Grid xs={2}>
                          <TextField
                            value={user.position}
                            name={"position"}
                            label="Position"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdateUser(event, index)}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            fullWidth
                            required
                          />
                        </Grid>

                        <Grid xs={1}>
                          <FormControlLabel
                            sx={{ fontWeight: 50, color: "text.secondary", py: 6, px: 4 }}
                            label={<Typography variant={"caption"}>{"Manager"}</Typography>}
                            labelPlacement={"top"}
                            control={
                              <Checkbox
                                sx={{ pt: 0.5 }}
                                checked={user.isTeamManager}
                                name={"isTeamManager"}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleAccountPermissionsUpdate(event, index)
                                }
                              />
                            }
                          />
                        </Grid>

                        <Grid xs={0.5}>
                          <IconButton onClick={() => handleDeleteUser(index)} sx={{ mt: 1.25, ml: 2 }}>
                            <SvgIcon component={Icons.XIcon} inheritViewBox sx={{ width: 18, height: 18 }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Collapse in={openAccordions.includes(index)} timeout="auto" unmountOnExit>
                        <UserOrgPermissionsSelect
                          setSelectedOrgPermissions={handlePermissionsUpdate(index)}
                          currentUser={user}
                          isUpdate={isUpdateMode}
                          updatedOrgs={isUpdateMode ? mapOrganizationDataToForm(organizations, true) : []}
                        />
                      </Collapse>
                      <Divider
                        style={{ background: "black" }}
                        sx={{
                          mt: 1,
                          mb: 3,
                        }}
                      />
                    </Box>
                  ))}

                  <Box sx={{ display: "block", width: "100%", marginTop: "20px" }}>
                    <Button onClick={handleNewUser}>
                      <SvgIcon
                        component={Icons.PlusSmallIcon}
                        inheritViewBox
                        sx={{ width: 12, height: 12, marginRight: 1.5 }}
                      />
                      Add User
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleGoBack} variant="outlined" sx={{ selfAlign: "start", marginRight: "auto" }}>
              Go Back
            </Button>
            <Button onClick={handleCancel} autoFocus variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} disabled={!isDa(userInfo)}>
              Save & Continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export async function loader({ params }: LoaderFunctionArgs) {
  const accountId = Number(params.accountId);
  const orgsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getOrgs(accountId, organizations), 200)
    : OrganizationService.getOrganizations(accountId);

  return defer({
    organizations: await orgsPromise,
  });
}

export async function action({ request, params }: { request: Request; params: LoaderFunctionArgs | any }) {
  try {
    const userFormData = (await request.json()) as UserFormData[];
    const formUsersRequest = mapUserFormDataToUserRequest(userFormData, Number(params.accountId));

    const createUsersResponse = await UserService.createUsers(formUsersRequest);
    return redirect(`/clients/` + params.accountId + "/confirm");
  } catch (e) {
    return e;
  }
}

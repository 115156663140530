import { Alert, Typography } from "@bakkt/bakkt-ui-components";

import { CollateralMinimal } from "../../Hooks/useGetCollateralRequest";
import { ReviewTransactionMinimal } from "../../Hooks/useReviewTransaction";
import { Asset, Wallet, WalletType } from "../../services/openAPI/internal";
import { getMinimunFeeAmount } from "../../utils/dataUtils";

interface ERC20BannerProps {
  wallet: Wallet | null;
  transaction: ReviewTransactionMinimal | Partial<CollateralMinimal>;
  assets: Asset[];
}

function ERC20Banner({ wallet, transaction, assets }: ERC20BannerProps) {
  const feeWallet = transaction.feeWallet;
  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      <Typography variant={"body2"}>
        {wallet?.type === WalletType.Custody ? transaction?.organizationName : "Cold Trust Fee"}, GAS Balance:{" "}
        {feeWallet?.availableBalance?.toFixed(8)} {feeWallet?.assetTicker}
      </Typography>
      <Typography variant={"body2"}>
        *Please ensure that the client has a balance of at least{" "}
        {getMinimunFeeAmount(wallet?.assetSymbol || "", assets)} {feeWallet?.assetTicker} for GAS before processing this
        withdrawal.
      </Typography>
    </Alert>
  );
}

export default ERC20Banner;

import {
  Alert,
  Button,
  countriesList,
  CountryListItem,
  Dialog,
  DialogTitle,
  StateListItem,
  statesList,
  TextField,
  usTerritoriesList,
} from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { matchIsValidTel, MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React, { ReactNode, useState } from "react";
import { redirect, useFetcher, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { Account, AccountRequest, Organization, User } from "../../services/openAPI/internal";
import { AccountService } from "../../services/serviceLoader";
import { mapClientDataToForm, mapClientFormDataToAccountRequest } from "../../utils/dataUtils";
import { isDa } from "../../utils/permissionsUtil";

export interface ClientFormData {
  id: number;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phone: ClientPhone;
  inputPhone: string;
}

export interface ClientPhone {
  countryCode: string;
  number: string;
}

export type LoaderData = {
  account: Account;
  organizations: Organization[];
  users: User[];
};
export default function AddEditClient() {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const fetcherData = fetcher.data;
  const isLoading = fetcher.state === "submitting";

  const [open, setOpen] = useState(true);
  const { accountChanges, setAccountChanges, userInfo } = useRootContext();

  //For update client/account flow
  const { accountId } = useParams();
  const isUpdateMode = accountId ? true : false;
  const { account: accountToUpdate } = (useRouteLoaderData("clientDetail") as LoaderData) ?? {};
  const clientFormData = mapClientDataToForm(accountToUpdate, isUpdateMode);
  const [account, setAccount] = useState<ClientFormData>(
    accountChanges?.account ? accountChanges.account : clientFormData
  );
  const states = [...statesList, ...usTerritoriesList];
  const handleClose = () => {
    setOpen(false);
    if (isUpdateMode) {
      //to parent route
      navigate("..");
    } else {
      navigate(-1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    updateField(name, value);
  };
  const handleSelectChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const { name, value } = event.target;
    updateField(name, value);
  };
  const updateField = (fieldName: string, value: string) => {
    setAccount((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handlePhoneChange = (value: string, info: MuiTelInputInfo) => {
    setAccount((prevData) => ({
      ...prevData,
      inputPhone: value,
      phone: {
        countryCode: info.countryCallingCode ? "+" + info.countryCallingCode : "",
        number: info.nationalNumber || "",
      },
    }));
  };

  const handleCancel = () => {
    setOpen(false);
    if (isUpdateMode) {
      //clear RootContext
      setAccountChanges((prevState) => ({
        ...prevState,
        account: null,
        organizations: [],
        users: [],
        isOrgEdited: false,
      }));
      //to parent route
      navigate("..");
    } else {
      setAccount({
        name: "",
        street1: "",
        street2: null,
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phone: {
          countryCode: "",
          number: "",
        } as ClientPhone,
        inputPhone: "",
      } as ClientFormData);
      navigate(-1);
    }
  };

  //update RootContext
  const handleAccountChange = () => {
    setAccountChanges((prevAccountChanges) => ({ ...prevAccountChanges, account }));
  };

  const handleSaveContinue = () => {
    if (isUpdateMode) {
      handleAccountChange();
      navigate("/clients/" + accountId + "/organizations/edit");
    } else {
      fetcher.submit(JSON.stringify(mapClientFormDataToAccountRequest(account)), {
        method: "post",
        encType: "application/json",
      });
    }
  };

  const isFormValid = () => {
    if (
      account.name !== "" &&
      account.street1 !== "" &&
      matchIsValidTel(account.inputPhone) &&
      account.country !== "" &&
      account.city !== "" &&
      account.zipCode !== "" &&
      account.state !== ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={true}>
      <DialogTitle title={"Confirm Client Details"}>Please confirm correct client details to continue.</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Grid sx={{ minHeight: 250 }}>
            <LoadingIndicator description={"Client is being created. Please wait..."} />
          </Grid>
        ) : (
          <>
            {fetcherData && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {`${fetcherData.message}: ${fetcherData.data?.message}` ||
                  "Failed to create client. Please try again later or contact support."}
              </Alert>
            )}
            <Grid container spacing={2} sx={{ margin: 0 }}>
              <Grid xs={6} sx={{ paddingLeft: 0 }}>
                <TextField
                  value={account.name}
                  onChange={handleInputChange}
                  name="name"
                  id="client-name"
                  label="Client Name"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>

              <Grid xs={6} sx={{ paddingRight: 0 }}>
                <TextField
                  value={account.street1}
                  onChange={handleInputChange}
                  name="street1"
                  id="address-line-1"
                  label="Address Line 1"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ margin: 0 }}>
              <Grid xs={6} sx={{ paddingLeft: 0 }}>
                <MuiTelInput
                  value={account.inputPhone}
                  onChange={handlePhoneChange}
                  name="inputPhone"
                  id="phone"
                  label="Phone"
                  variant="standard"
                  inputProps={{ maxLength: 17 }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid xs={6} sx={{ paddingRight: 0 }}>
                <TextField
                  value={account.street2 || ""}
                  onChange={handleInputChange}
                  name="street2"
                  id="address-line-2"
                  label="Address Line 2"
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ margin: 0 }}>
              <Grid xs={6} sx={{ paddingLeft: 0 }}>
                <FormControl variant="standard" sx={{ width: "100%" }} required>
                  <InputLabel id="select-country">Country</InputLabel>
                  <Select value={account.country} onChange={handleSelectChange} label="country" name="country">
                    {countriesList.map((country: CountryListItem, index: number) => (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} sx={{ paddingRight: 0 }}>
                <TextField
                  value={account.city}
                  onChange={handleInputChange}
                  name="city"
                  id="city"
                  label="City"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ margin: 0 }}>
              <Grid xs={6} sx={{ paddingLeft: 0 }}>
                {/* Keep this Grid Cell for proper layout */}
              </Grid>
              <Grid container spacing={2} xs={6} sx={{ paddingRight: 0 }}>
                <Grid xs={6}>
                  <TextField
                    value={account.zipCode}
                    onChange={handleInputChange}
                    name="zipCode"
                    id="zip-postal-code"
                    label="Zip/Postal Code"
                    variant="standard"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid xs={6}>
                  {account.country === "United States" && (
                    <FormControl variant="standard" sx={{ width: "100%" }} required>
                      <InputLabel id="select-state-province">State/Province</InputLabel>
                      <Select value={account.state} onChange={handleSelectChange} name="state" id="state-province-id">
                        {states.map((state: StateListItem, index: number) => (
                          <MenuItem key={index} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {account.country !== "United States" && (
                    <TextField
                      value={account.state}
                      onChange={handleInputChange}
                      name="state"
                      id="state-province-id"
                      label="State/Province"
                      variant="standard"
                      fullWidth
                      required
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant={"contained"}
          onClick={handleSaveContinue}
          autoFocus
          disabled={!isFormValid() || !isDa(userInfo)}
        >
          Save & Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export async function action({ request }: { request: Request }) {
  try {
    const formAccountRequest = (await request.json()) as AccountRequest;
    const createAccountResponse = await AccountService.createAccount(formAccountRequest);
    //TODO Typescript does not know about Axios interceptor change - need to figure out better solution
    return redirect("/organizations/" + (createAccountResponse as unknown as Account).id);
  } catch (e) {
    return e;
  }
}

import { formatCryptoQuantity, formatDollarAmountUsd, Icons, Typography } from "@bakkt/bakkt-ui-components";
import { Stack, SvgIcon, Unstable_Grid2 as Grid } from "@mui/material";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { useRootContext } from "../../RootLayout";
import { WalletTransaction } from "../../services/openAPI/internal";
import { getPriceByAssetQty, getSVGStringForTicker } from "../../utils/dataUtils";

export interface SettlementLineItem {
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
  createdOn: number;
  settlementTransaction: WalletTransaction;
}

interface SettlementLineItemProps {
  settlementLineItem: SettlementLineItem;
}

const ReviewSettlementLineItem = ({ settlementLineItem }: SettlementLineItemProps) => {
  const { priceFeed, assets } = useRootContext();

  return (
    <>
      <Grid container xs={12} justifyContent={"space-between"} sx={{ mb: 3 }}>
        <Grid xs={10}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <SvgIcon
              component={() =>
                AssetSVGIcon({
                  svgString: getSVGStringForTicker(assets, settlementLineItem?.settlementTransaction.assetTicker || ""),
                  title: settlementLineItem?.settlementTransaction.assetTicker || "",
                  sx: { width: 20, height: 20 },
                })
              }
              inheritViewBox
            />
            <Typography variant="subtitle1">{settlementLineItem?.settlementTransaction.assetTicker || ""}</Typography>
            <SvgIcon component={Icons.ArrowRightIcon} inheritViewBox sx={{ width: 10, height: 15 }} />
            <Typography variant="subtitle1">
              {settlementLineItem?.settlementTransaction.destinationName || ""}
            </Typography>
          </Stack>
        </Grid>
        <Grid direction="row" sx={{ textAlign: "right" }}>
          <Typography variant="subtitle1">
            {formatCryptoQuantity(Number(settlementLineItem?.settlementTransaction.quantity)).toLocaleString()}{" "}
            {settlementLineItem?.settlementTransaction.assetTicker || ""}
          </Typography>
          <Typography variant="subtitle1">
            {formatDollarAmountUsd(
              getPriceByAssetQty(
                settlementLineItem?.settlementTransaction.assetTicker as string,
                Number(settlementLineItem?.settlementTransaction.quantity),
                priceFeed,
                assets
              ) || 0
            )}{" "}
            USD
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewSettlementLineItem;

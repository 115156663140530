import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormLabel,
  Icons,
  InputLabel,
  Select,
} from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  SvgIcon,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useState } from "react";
import {
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import GoBackModal from "../../components/modal/GoBackModal";
import { useRootContext } from "../../RootLayout";
import { OrganizationRequest, Status } from "../../services/openAPI/internal";
import { OrganizationService } from "../../services/serviceLoader";
import { mapOrganizationDataToForm, mapOrgFormDataToOrganizationRequest } from "../../utils/dataUtils";
import { isDa } from "../../utils/permissionsUtil";
import { LoaderData } from "../clients/AddEditClient";

export interface OrganizationFormData {
  id: number;
  name: string;
  accountId: number;
  withdrawalAuthorization: {
    videoAuthorization: boolean;
    amountLimit: number;
  };
  numberOfConsensus: number;
}
export default function AddEditOrganization() {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const fetcherData = fetcher.data;
  const isLoading = fetcher.state === "submitting";

  //For update client/account flow
  const { accountChanges, setAccountChanges, userInfo } = useRootContext();

  const pathNames = useLocation()
    .pathname.split("/")
    .filter((path) => path !== "");
  const isUpdateMode = pathNames[pathNames.length - 1] === "edit" ? true : false;
  const { accountId } = useParams();
  let { organizations: orgsToUpdate } = (useRouteLoaderData("clientDetail") as LoaderData) ?? {};
  orgsToUpdate = orgsToUpdate?.filter((org) => org.status !== Status.Deleted);
  const orgFormData = mapOrganizationDataToForm(orgsToUpdate, isUpdateMode);
  const blankOrgRequest: OrganizationFormData = {
    id: -1,
    name: "",
    accountId: -1,
    withdrawalAuthorization: {
      videoAuthorization: false,
      amountLimit: 0,
    },
    numberOfConsensus: 0,
  };

  //states
  const [open, setOpen] = useState(true);
  const [organizations, setOrganizations] = useState<OrganizationFormData[]>(
    accountChanges?.organizations.length > 0 ? accountChanges.organizations : orgFormData
  );
  const [isErrorMessage, setIsErrorMessage] = useState<boolean>(false);
  const [goBack, setGoBack] = useState<boolean>(false);

  //sx styles
  const checkboxSx = { mt: 1 };
  const deleteSx = { width: 20, height: 20 };
  const gridSx = { mt: 4, pl: 3 };
  const addSx = { width: 25, height: 25, mr: 1, mt: 1 };
  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  // adding organization name to state
  const handleOrgName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newOrgName = event.target.value;
    setOrganizations((prevOrganizations) => {
      const updatedOrganizations = [...prevOrganizations];
      updatedOrganizations[index] = {
        ...updatedOrganizations[index],
        name: newOrgName,
      };
      return updatedOrganizations;
    });
  };

  // adding amount state
  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const rawValue = parseFloat(event.target.value);

    setOrganizations((prevOrganizations) => {
      const updatedOrganizations = [...prevOrganizations];
      updatedOrganizations[index] = {
        ...updatedOrganizations[index],
        withdrawalAuthorization: {
          ...updatedOrganizations[index].withdrawalAuthorization,
          amountLimit: rawValue,
        },
      };
      return updatedOrganizations;
    });
  };

  // add organization
  const newOrganization = () => {
    setOrganizations([...organizations, { ...blankOrgRequest }]);
  };

  //delete an organization
  const handleDeleteOrganization = (index: number) => {
    if (isUpdateMode && organizations[index] && organizations[index].id > 0) {
      setIsErrorMessage(true);
    } else {
      if (index === 0) {
        setOrganizations((prevOrganizations) => {
          const updatedOrganizations = [...prevOrganizations];
          updatedOrganizations[0] = { ...blankOrgRequest };
          return updatedOrganizations;
        });
      } else {
        setOrganizations((prevOrganizations) => {
          const updatedOrganizations = [...prevOrganizations];
          updatedOrganizations.splice(index, 1);
          return updatedOrganizations;
        });
      }
    }
  };

  //video authorization
  const handleWithdrawAuth = (index: number) => {
    setOrganizations((prevOrganizations) => {
      const updatedOrganizations = [...prevOrganizations];
      const organization = updatedOrganizations[index];
      organization.withdrawalAuthorization.videoAuthorization =
        !organization.withdrawalAuthorization.videoAuthorization;
      return updatedOrganizations;
    });
  };

  //approvers
  const handleApproverChange = (event: SelectChangeEvent<unknown>, index: number) => {
    const approverConsesus = parseInt(event.target.value as string);
    setOrganizations((prevOrganizations) => {
      const updatedOrganizations = [...prevOrganizations];
      updatedOrganizations[index] = {
        ...updatedOrganizations[index],
        numberOfConsensus: approverConsesus,
      };
      return updatedOrganizations;
    });
  };
  //update RootContext
  const handleAccountChange = () => {
    assignOrgsTempIds(organizations);
    setAccountChanges((prevAccountChanges) => ({ ...prevAccountChanges, organizations, isOrgEdited: true }));
  };
  //handle submit
  const handleSubmit = () => {
    if (isUpdateMode) {
      handleAccountChange();
      navigate("/clients/" + accountId + "/users/edit");
    } else {
      fetcher.submit(JSON.stringify(mapOrgFormDataToOrganizationRequest(organizations)), {
        method: "post",
        encType: "application/json",
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    if (isUpdateMode) {
      //clear RootContext
      setAccountChanges((prevState) => ({
        ...prevState,
        account: null,
        organizations: [],
        users: [],
        isOrgEdited: false,
      }));
      //to parent route
      navigate("..");
    } else {
      setOrganizations([{ ...blankOrgRequest }]);
      navigate("/");
    }
  };

  const isOrgValid = () => {
    for (const organization of organizations) {
      if (
        organization.name === "" ||
        organization.numberOfConsensus === 0 ||
        (organization.withdrawalAuthorization.videoAuthorization &&
          organization.withdrawalAuthorization.amountLimit === 0)
      ) {
        return false;
      }
    }
    return true;
  };

  const assignOrgsTempIds = (orgs: OrganizationFormData[]) => {
    let tempId = -1;
    orgs.map((org) => {
      if (org.id < 0) {
        org.id = tempId;
        tempId--;
      }
    });
  };

  // close dialog
  const handleGoBack = () => {
    setGoBack(true);
  };

  const handleConfirmGoBack = () => {
    if (isUpdateMode) {
      navigate(`/clients/${accountId}/edit`);
    } else {
      setOpen(false);
      navigate(-1);
    }
  };

  return (
    <>
      {goBack ? (
        <GoBackModal onConfirmGoBack={handleConfirmGoBack} goBack={goBack} setGoBack={setGoBack} />
      ) : (
        <Dialog open={open} onClose={handleGoBack} maxWidth="md">
          <DialogTitle title="Add Organizations">
            Use the table below to add or edit Client organizations. <br />
            Every organization must provide permissions to Initiate, Approve, and Manage Team to user(s).
          </DialogTitle>
          <DialogContent>
            {isLoading ? (
              <Grid sx={{ minHeight: 250 }}>
                <LoadingIndicator description={"Organzations being created. Please wait..."} />
              </Grid>
            ) : (
              <>
                {fetcherData && (
                  <Alert severity="error" sx={{ mb: 4 }}>
                    <AlertTitle>Error</AlertTitle>
                    {`${fetcherData.message}: ${fetcherData.data?.message}` ||
                      "Failed to create organzation. Please try again later or contact support."}
                  </Alert>
                )}
                {isErrorMessage && (
                  <Alert severity="error" sx={{ mb: 4 }}>
                    <AlertTitle>Error</AlertTitle>
                    {"Existing Organizations can't be deleted from the UI."}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid container xs={12} direction="row" alignItems="flex-end">
                    <Grid xs={5}>
                      <FormLabel>ORGANIZATION</FormLabel>
                    </Grid>
                    <Grid xs={2}>
                      <FormLabel>NUMBER OF APPROVERS</FormLabel>
                    </Grid>
                    <Grid xs={3.5}>
                      <FormLabel>WITHDRAW VIDEO AUTHORIZATION</FormLabel>
                    </Grid>
                    <Grid xs={1}>
                      <FormLabel>REMOVE</FormLabel>
                    </Grid>
                    <Grid xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  {organizations.map((organization, index) => (
                    <Grid xs={12} container alignItems="flex-end" key={index}>
                      <Grid xs={5}>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Name"
                            id={`organization-${index}`}
                            variant="standard"
                            value={organization.name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOrgName(event, index)}
                            fullWidth
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={2}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            id={`select-approvers-${index}-label`}
                            shrink={organization.numberOfConsensus !== 0}
                          >
                            Approvers
                          </InputLabel>
                          <Select
                            label="numberOfConsensus"
                            labelId={`select-approvers-label`}
                            id={`select-approvers-${index}`}
                            value={organization.numberOfConsensus || ""}
                            onChange={(event: SelectChangeEvent<unknown>) => handleApproverChange(event, index)}
                            required
                          >
                            {[1, 2, 3, 4, 5, 6].map((i) => (
                              <MenuItem id={"menuItem-" + i} key={"menuItem-" + i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={4}>
                        <Checkbox
                          sx={checkboxSx}
                          checked={organization.withdrawalAuthorization.videoAuthorization}
                          onChange={() => handleWithdrawAuth(index)}
                        />
                        <TextField
                          type="number"
                          label="Amount"
                          variant="standard"
                          disabled={!organization.withdrawalAuthorization.videoAuthorization}
                          value={
                            organization.withdrawalAuthorization.amountLimit !== 0
                              ? organization.withdrawalAuthorization.amountLimit
                              : ""
                          }
                          onChange={(event) => handleAmount(event as React.ChangeEvent<HTMLInputElement>, index)}
                          InputProps={{
                            startAdornment: organization.withdrawalAuthorization.videoAuthorization && (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                          required
                        />
                      </Grid>
                      <Grid xs={1} sx={gridSx}>
                        <IconButton onClick={() => handleDeleteOrganization(index)}>
                          <SvgIcon component={Icons.XIcon} inheritViewBox sx={deleteSx} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  {isDa(userInfo) && (
                    <Button onClick={newOrganization}>
                      <SvgIcon component={Icons.AddWalletIcon} color="primary" sx={addSx} />
                      Add Organization
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button sx={thirdActionSx} variant="outlined" onClick={handleGoBack}>
              Go Back
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!isOrgValid() || !isDa(userInfo)}
            >
              Save & Continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export async function action({ request, params }: { request: Request; params: LoaderFunctionArgs | any }) {
  try {
    const formOrgRequest = (await request.json()) as OrganizationRequest[];
    const updatedFormOrgRequest = formOrgRequest.map((orgRequest) => {
      return { ...orgRequest, accountId: Number(params.accountId) };
    });

    const createOrganizationResponse = await OrganizationService.createOrganizations(updatedFormOrgRequest);
    return redirect(`/users/` + params.accountId);
  } catch (e) {
    return e;
  }
}
